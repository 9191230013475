import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { INLINES } from '@contentful/rich-text-types'
import {
	FormControl,
	InputLabel,
	MenuItem,
	OutlinedInput,
	Select,
	TextField,
} from '@material-ui/core'
import React, { useEffect, useMemo } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import { Controller, useForm } from 'react-hook-form'
import Button from '../../../shared/components/atoms/Button/Button'
import Checkbox from '../../../shared/components/atoms/checkbox/checkbox'
import Icon from '../../../shared/components/atoms/Icon/Icon'
import ButtonLoading from '../../../shared/components/molecules/buttonLoading/buttonLoading'
import DataPolicy from '../../../shared/components/molecules/data-policy/data-policy'
import useBrowserMode from '../../../shared/hooks/useBrowserMode'
import useDocumentCountry from '../../../shared/hooks/useDocumentCountry'
import useCountries from '../../../shared/hooks/useCountries'
import useLocaleMode from '../../../shared/hooks/useLocaleMode'
import { axiosInstance } from '../../../shared/services/axiosInstance'
import ProfileService from '../../../shared/services/profile'
import SessionService from '../../../shared/services/session'
import * as apiconstants from '../../../shared/state/constants/apiConstants'
import * as constants from '../../../shared/state/constants/appConstants'
import {
	V_NUMBER_ID,
	V_TYPE_ID,
} from '../../../shared/state/constants/reactHookFormValidations'
import * as RecaptchaKey from '../../../shared/state/constants/reCaptcha'
import ContactInfo from './contact-info'
import './contactUsForm.scss'
import { convertText } from '../../../shared/utils/convertFirstCharacterToUppercase'

const HAVE_W2C_LIST = ['es-CO', 'es-EC', 'es-DO', 'es-PR']

const ContactUsForm = ({ countries, setModal, checksDePoliticas }) => {
	const { isBrowser } = useBrowserMode()
	const { locale } = useLocaleMode()
	const { filterDocumentsType, getCountryByCode} = useDocumentCountry()
	const { filterDepartmentsCountry, filterCitiesByDeparment} = useCountries()
	const [dataUser] = React.useState(isBrowser && SessionService.getUserData())
	const userName = isBrowser && SessionService.getUserName()
	const [contactList] = React.useState(
		() => ContactInfo[locale] || ContactInfo.default
	)
	const [isLoaderVisible, setLoaderVisible] = React.useState(false)
	const [labelWidth] = React.useState(0)
	const [labelWidthTwo] = React.useState(0)
	const inputLabel = React.useRef(null)
	const inputLabelTwo = React.useRef(null)
	const recaptchaRef = React.useRef(null)
	const countriesList = countries
	const currentCountry = isBrowser
		? countriesList &&
		  countriesList.filter(country =>
				locale.includes(country.codigoDeDosLetras)
		  )[0].codigoDelPais
		: '1'

	const {
		register,
		control,
		handleSubmit,
		errors,
		triggerValidation,
		setError,
	} = useForm({
		defaultValues: {
			fullname: userName || '',
			email: dataUser?.email || '',
			country: currentCountry || '',
			document: dataUser?.numberId || '',
			documentType: dataUser?.typeId || '',
			department: dataUser?.mainCountryState  || '',
			city: dataUser?.mainCity  || '',
			cellPhone: '',
		},
	})

	const [values, setValues] = React.useState({
		country: currentCountry || '',
		contactType: '',
		fullname: userName || '',
		email: dataUser?.email || '',
		document: dataUser?.numberId || '',
		documentType: dataUser?.typeId || '',
		department: dataUser?.mainCountryState  || '',
		city: dataUser?.mainCity  || '',
		cellPhone: '',
	})

	const isPR = Number(values.country) === 12
	const notHaveCountry = values.country === null

	const currentCountryInfo = getCountryByCode(
		Number(values.country || currentCountry)
	)

	const documentInfo = useMemo(() => {
		const _currentCountry = getCountryByCode(
			Number(values.country || currentCountry)
		)
		const _locale = _currentCountry.locale || locale
		return filterDocumentsType(_locale)
	}, [values?.country])

	const deparmentsInfo = useMemo(() => {
		const _currentCountry = getCountryByCode(
			Number(values.country || currentCountry)
		)
		const _locale = _currentCountry.locale || locale
		return filterDepartmentsCountry(_locale)
	}, [values?.country])


	const citiesInfo = useMemo(() => {
		const _currentDepartment = Number(values.department)
		const _currentCountry = getCountryByCode(
			Number(values.country || currentCountry)
		)
		const _locale = _currentCountry.locale || locale

		 if (_locale === 'es-PR'){
			return filterDepartmentsCountry(_locale)
		 } else {
			return filterCitiesByDeparment({
				locale: _locale,
				deparmentId: _currentDepartment
			})
		 }
		 
	}, [values?.department, values?.country])

	const haveW2c = useMemo(() => {
		const _currentCountry = getCountryByCode(
			Number(values.country || currentCountry)
		)
		const _locale = _currentCountry.locale || locale
		return HAVE_W2C_LIST.includes(_locale)
	}, [values?.country])

	const handleReloadCaptcha = async () => {
		return await recaptchaRef.current.execute()
	}

	const handleChange = props => event => {
		triggerValidation({ name: event.target.name })
		if (
			event.target.name === 'country' ||
			event.target.name === 'contactType' ||
			event.target.name === 'department' ||
			event.target.name === 'city'
		) {
			setValues({ ...values, [event.target.name]: event.target.value })
		}
	}

	const onSubmit = data => {
		setLoaderVisible(true)
		if (data) {
			const contactListSelected =
				contactList.filter(
					contact => contact.value === values.contactType
				)?.[0] || null

			const payload = {
				userName: data.fullname,
				emailUser: data.email,
				countryId: values.country,
				cityId: isPR ? values.department : values.city,
				cellPhone: data.cellPhone ? currentCountryInfo.prefix + data.cellPhone : '',
				reason: values.contactType,
				commentary: data.messagge,
				typeId: data.documentType,
				numberId: data.document,
				captcha: recaptchaRef.current.getValue(),
				reasonText: contactListSelected.name || '',
			}

			axiosInstance
				.post(apiconstants.CONTACTUS, payload)
				.then(response => {
					if (response.data.status === constants.successStatus) {
						setModal(true)
						const payloadTerms = {}

						checksDePoliticas.forEach(({ idEtiqueta }) => {
							payloadTerms[idEtiqueta] = data[idEtiqueta] ? 1 : 0
						})

						ProfileService.updateHabeas(payloadTerms, () => {}, () => {})
					} else if (
						response.data.status === 400 &&
						response.data.errorCode === 'DEM001'
					) {
						setError('email', 'validation', response.data.userMessage)
					}
					setLoaderVisible(false)
				})
				.catch(err => {
					console.log(err)
					setLoaderVisible(false)
				})
				.finally(() => {
					handleReloadCaptcha()
				})
		}
	}

	const optionsChecks = {
		renderNode: {
			[INLINES.HYPERLINK]: (node, next) => {
				return (
					<a href={node.data.uri} rel='noopener noreferrer' target='_blank'>
						{next}
					</a>
				)
			},
		},
	}

	useEffect(() => {
		handleReloadCaptcha()
	}, [])

	return (
		<>
			<form
				id='form-contactus'
				className='f-form-contactus'
				onSubmit={handleSubmit(onSubmit)}
				autoComplete='off'
			>
				<TextField
					error={errors.fullname != null}
					color='secondary'
					name='fullname'
					autoFocus={true}
					type='text'
					className='f-form-input'
					id='f-fullname-input'
					onChange={handleChange()}
					label={'¿Cuál es tu nombre y apellidos?'}
					variant='outlined'
					helperText={
						errors.fullname && errors.fullname.type === 'required'
							? 'Este campo es requerido.'
							: errors.fullname && errors.fullname.type === 'minLength'
							? 'La cantidad mínima de caracteres es 10 (diez).'
							: errors.fullname && errors.fullname.type === 'maxLength'
							? 'Se ha alcanzado la cantidad máxima de caracteres.'
							: errors.fullName && errors.fullname.type === 'pattern'
							? 'Éste campo debe contener únicamente letras.'
							: ''
					}
					inputRef={register({
						required: true,
						minLength: 8,
						maxLength: 50,
						pattern: constants.NAME_PATTERN,
					})}
				/>
				<TextField
					error={errors.email != null}
					color='secondary'
					name='email'
					type='text'
					autoComplete='off'
					// value={dataUser && values.email}
					className='f-form-input'
					id='f-contact-email-input'
					onChange={handleChange()}
					label={'¿Cuál es tu correo electrónico?'}
					variant='outlined'
					inputRef={register({
						required: true,
						pattern: constants.EMAIL_PATTERN,
					})}
					helperText={
						errors.email && errors.email.type === 'required'
							? 'Tu correo electrónico es requerido.'
							: errors.email && errors.email.type === 'pattern'
							? 'El correo electrónico ingresado no es válido.'
							: errors.email && errors.email.message
					}
				/>

				<FormControl
					variant='outlined'
					className='f-form-input f-form-input-select f-form-input-small'
					error={errors.country != null}
				>
					<InputLabel ref={inputLabel} htmlFor='outlined-country-select'>
						¿Cuál es tu país?
					</InputLabel>
					<Select
						name='country'
						value={values.country || ''}
						onChange={handleChange()}
						required
						input={
							<OutlinedInput
								labelWidth={labelWidth}
								name='country'
								id='outlined-country-select'
							/>
						}
					>
						{countriesList.map((item, index) => (
							<MenuItem key={index} value={item.codigoDelPais}>
								{item.titulo}
							</MenuItem>
						))}
					</Select>
					<Icon icon='icon-chevron-down lonchmara' size='0-7' tagtype='i' />
				</FormControl>

				{haveW2c && (
					<>
						{ notHaveCountry ? null : 
						<FormControl
							variant='outlined'
							className='f-form-input f-form-input-select f-form-input-small'
							error={errors.department != null}
						>
							<InputLabel htmlFor='outlined-department-select'>
								¿Cuál es tu departamento?
							</InputLabel>
							<Select
								name='department'
								value={values.department || ''}
								onChange={handleChange()}
								required
								input={
									<OutlinedInput
										labelWidth={labelWidth}
										name='department'
										id='outlined-department-select'
									/>
								}
								helperText={
									errors.department && errors.department === 'required'
										? 'Este campo es requerido.'
										: ''
								}
							>
								{deparmentsInfo?.departamentos.map((depart, index) => (
									<MenuItem key={index} value={depart.codigo}>
										{depart.nombre}
									</MenuItem>
								))}
							</Select>
							<Icon icon='icon-chevron-down lonchmara' size='0-7' tagtype='i' />
						</FormControl>
						}

						{ (isPR || notHaveCountry) ? null :
						 <FormControl
							variant='outlined'
							className='f-form-input f-form-input-select f-form-input-small'
							error={errors.city != null}
						>
							<InputLabel ref={inputLabel} htmlFor='outlined-city-select'>
								¿Cuál es tu ciudad?
							</InputLabel>
							<Select
								name='city'
								value={values.city || ''}
								onChange={handleChange()}
								input={
									<OutlinedInput
										labelWidth={labelWidth}
										name='city'
										id='outlined-city-select'
									/>
								}
							>
								{citiesInfo.map(city => (
									<MenuItem key={city.codigo} value={city.codigo}>
										{city.nombre}
									</MenuItem>
								))}
							</Select>
							<Icon icon='icon-chevron-down lonchmara' size='0-7' tagtype='i' />
						</FormControl>
						}

						<FormControl
							variant='outlined'
							className='f-form-input f-input-material f-form-select'
							error={errors.documentType != null}
						>
							<InputLabel htmlFor='outlined-gender-simple'>
								{documentInfo?.inputTipoDeDocumento}
							</InputLabel>
							<Controller
								as={
									<Select
										MenuProps={{
											disableScrollLock: true,
										}}
										onChange={handleChange()}
									>
										{documentInfo?.tipoDeDocumento.map((option, index) => (
											<MenuItem value={option.value} key={index}>
												{option.name}
											</MenuItem>
										))}
									</Select>
								}
								rules={V_TYPE_ID}
								defaultValue=''
								name='documentType'
								control={control}
							/>
							<Icon icon='icon-chevron-down lonchmara' size='0-7' tagtype='i' />
						</FormControl>

						<TextField
							error={errors.document != null}
							color='secondary'
							name='document'
							autoFocus={true}
							// value={dataUser && values.document}
							type='text'
							className='f-form-input'
							id='f-document-input'
							onChange={handleChange()}
							label={documentInfo?.inputNumeroDeDocumento}
							variant='outlined'
							helperText={errors.document?.message || ''}
							inputRef={register(V_NUMBER_ID)}
						/>

						{notHaveCountry ? null : 
						<TextField
							error={errors.cellPhone != null}
							color='secondary'
							name='cellPhone'
							autoFocus={true}
							/* value={values.cellPhone} */
							type='text'
							className='f-form-input'
							id='f-cellPhone-input'
							onChange={handleChange()}
							label={'Teléfono'}
							variant='outlined'
							helperText={
								errors.cellPhone && errors.cellPhone.type === 'required'
									? 'Este campo es requerido.'
									: errors.cellPhone && errors.cellPhone.type === 'minLength'
									? 'No cumple con los '+currentCountryInfo.cellDig+' digitos requeridos.'
									: errors.cellPhone && errors.cellPhone.type === 'pattern'
									? 'Éste campo debe contener únicamente números.'
									: ''
							}
							inputRef={register({
								required: true,
								minLength: currentCountryInfo.cellDig,
								pattern: constants.NUMBER_PATTERN,
							})}
						/>
						}
					</>
				)}

				<FormControl
					variant='outlined'
					className='f-form-input f-form-input-select f-form-input-small'
					error={errors.contactType != null}
				>
					<InputLabel ref={inputLabelTwo} htmlFor='outlined-contactType-select'>
						Tipo de contacto
					</InputLabel>
					<Select
						name='contactType'
						value={values.contactType && values.contactType}
						onChange={handleChange()}
						input={
							<OutlinedInput
								labelWidth={labelWidthTwo}
								name='contactType'
								id='outlined-contactType-select'
							/>
						}
					>
						{contactList.map((item, index) => {
							return (
								<MenuItem key={index} value={item.value}>
									{item.name}
								</MenuItem>
							)
						})}
					</Select>
					<Icon icon='icon-chevron-down lonchmara' size='0-7' tagtype='i' />
				</FormControl>

				<TextField
					error={errors.messagge != null}
					id='outlined-multiline-static'
					label='¿Cuál es tu mensaje?'
					multiline
					rows='4'
					className='f-form-input'
					margin='normal'
					onChange={handleChange()}
					variant='outlined'
					name='messagge'
					helperText={
						errors.messagge && errors.messagge.type === 'required'
							? 'Este campo es requerido.'
							: errors.messagge && errors.messagge.type === 'minLength'
							? 'La cantidad mínima de caracteres es 10 (diez).'
							: errors.messagge && errors.messagge.type === 'maxLength'
							? 'Se ha alcanzado la cantidad máxima de caracteres.'
							: ''
					}
					inputRef={register({ required: true, minLength: 8, maxLength: 5000 })}
				/>

				{checksDePoliticas?.map(
					({ idEtiqueta, informacinPrincipal }, index) => {
						return (
							<Checkbox
								mainClassName='f-terms-container'
								key={index}
								errors={errors}
								name={idEtiqueta}
								showMessageError={false}
								register={register({
									required: 'Debes seleccionar este campo',
								})}
								onChange={() => triggerValidation(idEtiqueta)}
								label={
									<>
										{documentToReactComponents(
											informacinPrincipal.json,
											optionsChecks
										)}
									</>
								}
							/>
						)
					}
				)}

				<DataPolicy />

				<ReCAPTCHA
					ref={recaptchaRef}
					size='invisible'
					sitekey={RecaptchaKey.key}
				/>

				<ButtonLoading
					// isEnabled={
					//   dataUser === null ?
					//     (Object.values(formState.touched).length === 0 || !formState.isValid ||
					//       (Object.values(formState.touched).length === 2 && (values.country === '' || values.contactType === '' || errors.messagge !== null))) : false}
					button={
						<Button
							id='f-changepass-btn'
							type='input'
							classname='f-button f-button--primary'
							text={convertText('CONTACTARME')}
						/>
					}
					isShowingLoader={isLoaderVisible}
					bgClass='primary'
				/>
			</form>
		</>
	)
}

export default ContactUsForm
