import { graphql, useStaticQuery } from 'gatsby'
/* import { countries } from '../utils/countriesList' */

export default function useCountries() {
	const result = useStaticQuery(
		graphql`
			query countries {
				allContentfulTenaPaises {
					nodes {
						node_locale
						codigoDeDosLetras
						lenguaje
						titulo
						dominio
						nombreDepartamento
						nombreCiudad
						departamentos {
							nombre
							codigo
							id
						}
						ciudades {
							codigo
							codigoDepartamento
							id
							nombre
						}
						prefijoTelefonico
					}
				}
			}
		`
	)

	const actualLocale = process.env.LOCALE || "es-CO"
	const resultQuery = result?.allContentfulTenaPaises?.nodes

	// Info country
	const currentCountry = resultQuery.filter(
		e => e.node_locale === "es-CO")
	
	const getCountryInfo = locale => {
		const resultCmsInfo = currentCountry.filter(content =>
		  locale.includes(content.codigoDeDosLetras)
		)[0]
	
		return { ...resultCmsInfo }
	}

	const currentCountryInfo = getCountryInfo(actualLocale)

	// info Cities
	const mapCitiesOptions = cities =>
    cities.map(option => ({
      value: option.codigo || option.value,
      name: option.nombre || option.name,
    })) || []

	const filterCitiesByDeparment = (
		{deparmentId, locale},
		mapToOptions = true
	  ) => {
		let options = getCountryInfo(locale).ciudades
		if (deparmentId) {
		  options = options?.filter(
			city =>
			  city.codigoDepartamento?.toString() === deparmentId?.toString()
		  )
		}
		return options
	  }

	const filterDepartmentsCountry = country =>{
    return currentCountry.filter(e =>
        e.codigoDeDosLetras
            .toLowerCase()
            .includes(
                country?.split('-')[1].toLowerCase() || process.env.LOCALE?.split('-')[1].toLowerCase() || 'co'
            )
        )[0]
	}

	
	const filterCitiesCountry = country =>{
		return result.allContentfulTenaPaises.nodes.filter(
			e => e.node_locale === 'es-CO')
			.filter(e =>
			e.codigoDeDosLetras
				.toLowerCase()
				.includes(
					country?.split('-')[1].toLowerCase() || process.env.LOCALE?.split('-')[1].toLowerCase() || 'co'
				)
			)[0]
		}

	return {currentCountryInfo, filterDepartmentsCountry, filterCitiesCountry, filterCitiesByDeparment, getCountryInfo, mapCitiesOptions}
}
