import React from 'react'
import Check from '../../../shared/assets/images/icon-check.svg'
import Button from '../../../shared/components/atoms/Button/Button'
import AlertMessagge from '../../../shared/components/molecules/alertMessagge/alertMessagge'
import useHomeLinkMode from '../../../shared/hooks/useHomeLinkMode'
import BannerRegister from '../../molecules/bannerRegister/bannerRegister'
import ContactUsForm from '../../molecules/contactUsForm/contactUsForm'
import LayoutCardMark from '../layoutCardMark/layoutCardMark'
import './contactUsContent.scss'
import { convertText } from '../../../shared/utils/convertFirstCharacterToUppercase'

const ContactUsContent = props => {
	const bannerInfo = {
		images: props.banner,
		title: props.title,
	}
	const { homeLink } = useHomeLinkMode()
	const [modalIsVisible, setModalVisible] = React.useState(false)

	return (
		<section className='f-contactus-wrapper'>
			<BannerRegister
				banner={props.banner}
				bannerInfo={bannerInfo}
				isMobile={props.isMobile}
			/>
			{modalIsVisible ? (
				<LayoutCardMark markType='regalblue'>
					<div className='f-contactus-alert'>
						<AlertMessagge
							icon={<img src={Check} alt='Alert' />}
							type='primary'
							messagge='¡Tu mensaje se ha enviado exitosamente!'
							description='Nuestro equipo TENA pronto estará en contacto contigo.'
							button={
								<div className='f-alert buttons'>
									<Button
										text={convertText('ENVIAR OTRO MENSAJE')}
										classname='f-button f-button--outlined'
										type='button'
										onClickAction={() => {
											setModalVisible(false)
										}}
									/>
									<Button
										text={convertText('IR AL INICIO')}
										classname='f-button f-button--primary'
										type='button'
										onClickAction={() => {
											window.location.href = homeLink
										}}
									/>
								</div>
							}
						/>
					</div>
				</LayoutCardMark>
			) : (
				<LayoutCardMark
					markType='regalblue'
					title={props.markTitle}
					description={props.markDescription}
				>
					<ContactUsForm
						setModal={setModalVisible}
						countries={props.paises}
						userData={props.userData}
						checksDePoliticas={props.checksDePoliticas}
					/>
				</LayoutCardMark>
			)}
		</section>
	)
}

export default ContactUsContent
