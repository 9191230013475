import React from 'react'
import Img from 'gatsby-image'
import './bannerRegister.scss'

const BannerRegister = props => {
	const imgDesktop = props.bannerInfo.images.bannerDesktop
	const imgMobile = props.bannerInfo.images.bannerMobile
	const description =
		(props.bannerInfo.images.descripcionImagen &&
			props.bannerInfo.images.descripcionImagen) ||
		props.bannerInfo.images.description

	return (
		<div className='f-banner'>
			<Img
				fluid={props.isMobile ? imgMobile.fluid : imgDesktop.fluid}
				alt={description}
			/>
			<h1
				className={
					props.slug !== 'registro'
						? 'f-title-change f-text-color-' +
						  (props.banner ? props.banner.colorTexto : '') +
						  ' f-banner-' +
						  (props.banner ? props.banner.localizacionTexto : '')
						: null
				}
			>
				{props.bannerInfo.title}
				<span>{props.bannerInfo.subtitle && props.bannerInfo.subtitle}</span>
			</h1>
		</div>
	)
}

export default BannerRegister
