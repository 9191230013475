import React from 'react'
import { BLOCKS } from '@contentful/rich-text-types'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import './layoutCardMark.scss'

const LayoutCardMark = ({ markType, title, description, children }) => {

	const options = {
		renderNode: {
			[BLOCKS.PARAGRAPH]: (node, children) => (
				<p className='f-p'>{children}</p>
			),
		},
	}

	return (
		<div className='f-mark-card'>
			<div className='f-mark-card-header'>
				<div className={`f-mark f-mark--${markType}`}></div>
				<h2 className='f-h2'>{title}</h2>
				<>
				{documentToReactComponents(description, options)}
				</>
			</div>
			<div className='f-mark-card-body'>{children}</div>
		</div>
	)
}

export default LayoutCardMark
