import { graphql, useStaticQuery } from 'gatsby'
import { countries } from '../utils/countriesList'

export default function useDocumentCountry() {
	const result = useStaticQuery(
		graphql`
			query IDENTIFICATION_DOCUMENT_WEBINAR {
				allContentfulTenaRegistroV2(
					filter: { slug: { eq: "informacion-personal" } }
				) {
					nodes {
						node_locale
						inputNumeroDeDocumento
						inputTipoDeDocumento
						tipoDeDocumento {
							name
							value
						}
					}
				}
			}
		`
	)

	const removePrefix = cellPhone => {
		let cellPhoneNoPrefix = cellPhone
		countries.forEach(country => {
			if (cellPhone.includes(country.prefix)) {
				cellPhoneNoPrefix = cellPhone.replace(country.prefix, '')
			}
		})
		return cellPhoneNoPrefix
	}

	const filterDocumentsType = country =>
		result.allContentfulTenaRegistroV2.nodes.filter(e =>
			e.node_locale
				.toLowerCase()
				.includes(
					country?.toLowerCase() || process.env.LOCALE?.toLowerCase() || 'es-co'
				)
		)[0]

	const getCountryByCode = code =>
		countries.filter(
			country => country.code === (code || parseInt(process.env.COUNTRY_CODE))
		)[0]

	return { countries, filterDocumentsType, getCountryByCode, removePrefix }
}
