import {
	ACCOUNT_POINTS,
	ACCOUNT_REDEMPTIONS,
	AUTH_EMAIL,
	AUTH_LOGIN,
	UPDATE_PROFILE,
	VALIDATE_CHANGE_EMAIL,
	ACCOUNT_CANCEL_CHANGE_EMAIL,
	ACCOUNT_CHANGE_EMAIL,
	ACCOUNT_CHANGE_EMAIL_CONFIRM,
	ACCOUNT_CHANGE_EMAIL_OTP,
	UPDATE_HABEAS,
} from '../state/constants/apiConstants'
import { axiosInstance } from './axiosInstance'
import SessionService from './session'

const ProfileService = {
	updateProfile: async (data, success, error) => {
		try {
			success(
				await axiosInstance.put(UPDATE_PROFILE, data, {
					headers: {
						'Content-Type': 'application/json',
						Authorization: SessionService.getIdTokenData(),
					},
				})
			)
		} catch (_error) {
			error(_error)
		}
	},

	deleteUser: async (email, success, error) => {
		try {
			success(
				await axiosInstance.delete(`${UPDATE_PROFILE}/${email}`, {
					headers: {
						'Content-Type': 'application/json',
						Authorization: SessionService.getIdTokenData(),
					},
				})
			)
		} catch (_error) {
			error(_error)
		}
	},

	updateDirection: async (data, success, error) => {
		try {
			success(await axiosInstance.post(UPDATE_PROFILE, data))
		} catch (_error) {
			error(_error)
		}
	},

	validateEmail: async (data, success, error) => {
		try {
			success(await axiosInstance.post(AUTH_EMAIL, data))
		} catch (_error) {
			error(_error)
		}
	},

	authLogin: async (data, success, error) => {
		try {
			success(await axiosInstance.post(AUTH_LOGIN, data))
		} catch (_error) {
			error(_error)
		}
	},

	myPoints: async (success, error) => {
		try {
			const data = SessionService.getUserData()
			const config = {
				headers: {
					'Content-Type': 'application/json',
					Authorization: SessionService.getIdTokenData(),
				},
			}
			const endpoint = `${ACCOUNT_POINTS}/${data.email}`

			success(await axiosInstance.get(endpoint, config))
		} catch (_error) {
			error(_error)
		}
	},

	myPointsTest: async email => {
		return await axiosInstance
			.get(ACCOUNT_POINTS + `?email=${email}`)
			.then(resp => resp.data)
	},

	myRedemptions: async (success, error) => {
		try {
			// is valid token
			success(
				await axiosInstance.get(ACCOUNT_REDEMPTIONS, {
					headers: {
						'Content-Type': 'application/json',
						Authorization: SessionService.getIdTokenData(),
					},
				})
			)
		} catch (_error) {
			error(_error)
		}
	},

	validateChangeEmail: async () => {
		return await axiosInstance.get(VALIDATE_CHANGE_EMAIL, {
			headers: {
				'Content-Type': 'application/json',
				Authorization: SessionService.getIdTokenData(),
			},
		})
	},

	changeEmail: async payload => {
		return await axiosInstance.post(ACCOUNT_CHANGE_EMAIL, payload, {
			headers: {
				'Content-Type': 'application/json',
				Authorization: SessionService.getIdTokenData(),
			},
		})
	},

	confirmChangeEmail: async payload => {
		return await axiosInstance.post(ACCOUNT_CHANGE_EMAIL_CONFIRM, payload, {
			headers: {
				'Content-Type': 'application/json',
				Authorization: SessionService.getIdTokenData(),
			},
		})
	},

	cancelEmailChange: async () => {
		return await axiosInstance.delete(ACCOUNT_CANCEL_CHANGE_EMAIL, {
			headers: {
				'Content-Type': 'application/json',
				Authorization: SessionService.getIdTokenData(),
			},
		})
	},

	sendOtpChangeEmail: async body => {
		return await axiosInstance.post(ACCOUNT_CHANGE_EMAIL_OTP, body, {
			headers: {
				'Content-Type': 'application/json',
				Authorization: SessionService.getIdTokenData(),
			},
		})
	},

	updateHabeas: async (data, success, error) => {
		try {
			success(
				await axiosInstance.put(UPDATE_HABEAS, data, {
					headers: {
						'Content-Type': 'application/json',
						Authorization: SessionService.getIdTokenData() || null,
					},
				})
			)
		} catch (_error) {
			error(_error)
		}
	},
}

export default ProfileService
