import React from 'react'
import { Checkbox } from '@material-ui/core'
import Alert from '../alert/alert'
import './checkbox.scss'

const CheckboxAtom = ({
	errors,
	name,
	register,
	label,
	className,
	mainClassName = '',
	showMessageError = true,
	...propsCheckbox
}) => {
	return (
		<div className={mainClassName}>
			<div className={`f-checkbox ${className}`}>
				<div>
					<Checkbox
						name={name}
						id={name}
						{...propsCheckbox}
						className={`f-checkbox-component ${errors[name] &&
							'f-checkbox-component--error'}`}
						inputRef={register}
					/>
				</div>
				<label className='f-checkbox__label' htmlFor={name}>
					{label}
				</label>
			</div>
			{errors[name] && showMessageError && (
				<Alert alertType='error' description={errors[name].message} />
			)}
		</div>
	)
}

export default CheckboxAtom
