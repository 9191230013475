import { graphql } from 'gatsby'
import React from 'react'
import ContactUsContent from '../components/organisms/contactUsContent/contactUsContent'
import Layout from '../shared/components/organisms/layout/layout'
import SEO from '../shared/components/seo'
import useMobileMode from '../shared/hooks/useMobileMode'

const Contacto = props => {
	const { isMobile, currentPage } = useMobileMode()
	const {
		banner,
		titulo,
		tituloGeneral,
		description,
		metaDescripcion,
		metaTitulo,
		checksDePoliticas,
	} = props.data.allContentfulTenaSeccionContactanos.nodes[0]
	return (
		<Layout title={metaTitulo} isMobile={isMobile} currentPage={currentPage}>
			<SEO
				title={metaTitulo}
				description={metaDescripcion.metaDescripcion}
				lang={props.pageContext.language}
				slug={'/contacto/'}
				image={banner.bannerDesktop?.fluid.src}
			/>
			<ContactUsContent
				markTitle={tituloGeneral}
				markDescription={description.json}
				banner={banner}
				title={titulo}
				isMobile={isMobile}
				paises={props.data.allContentfulTenaPaises.nodes}
				checksDePoliticas={checksDePoliticas}
			/>
		</Layout>
	)
}

export const query = graphql`
	query CONTACT_US($language: String!) {
		allContentfulTenaSeccionContactanos(
			filter: { node_locale: { eq: $language } }
		) {
			nodes {
				banner {
					bannerDesktop {
						fluid(maxWidth: 1800, quality: 90) {
							src
							srcSet
							base64
							aspectRatio
							sizes
						}
						description
					}
					cta
					colorTexto
					descripcionImagen
					enlace
					nombre
					localizacionTexto
					bannerMobile {
						fluid(maxWidth: 600, quality: 90) {
							src
							srcSet
							base64
							aspectRatio
							sizes
						}
						description
					}
				}
				checksDePoliticas {
					idEtiqueta
					informacinPrincipal {
						json
					}
				}
				titulo
				tituloGeneral
				description {
					json
				}
				metaDescripcion {
					metaDescripcion
				}
				metaTitulo
			}
		}
		allContentfulTenaPaises(
			sort: { fields: orden, order: ASC }
			filter: { node_locale: { eq: "es-CO" } }
		) {
			nodes {
				titulo
				codigoDelPais
				codigoDeDosLetras
			}
		}
	}
`

export default Contacto
